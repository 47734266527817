import { defaultFeatureToggles } from '@constants/featureToggle';
import {
  AccountMessages,
  FilterMessages,
  GlobalMessages,
  Messages,
  Signals,
} from '@customTypes/Messages';
import { LanguageSpecificUrls } from '@customTypes/apiCompoundLanguageSpecificUrl';
import { serverUtils } from '@utils/serverUtils/serverUtils';

import { MessagebusRecord, MessageHooks } from '../serverMessageHooks/serverMessageHooks';

export const messaging = {
  getMessageHooksFactory() {
    return serverUtils.isServerSide()
      ? require('../serverMessageHooks/serverMessageHooks').messageHooksFactory
      : require('@nxte-nl/message-hooks').messageHooksFactory;
  },
};

const messageHooksFactory: <
  TStateMessages extends MessagebusRecord,
  TSignalMessages extends MessagebusRecord,
>(
  defaults?: Partial<TStateMessages>
) => MessageHooks<TStateMessages, TSignalMessages> = messaging.getMessageHooksFactory();
export const defaultUrls: LanguageSpecificUrls = {
  pageEntity: [],
};

export const messageHooks = messageHooksFactory<Messages, Signals>({
  [GlobalMessages.FeatureToggles]: defaultFeatureToggles,
  [FilterMessages.ModalFiltersSelected]: false,
  [GlobalMessages.Urls]: defaultUrls,
  [AccountMessages.UserInfoLoading]: true,
});
