import React from 'react';

import { callbackHooks } from '@nxte-nl/callback-hooks';

import { FEATURE_TOGGLES_LOCAL_STORAGE_ID } from '@constants/featureToggle';
import { FeatureToggleValues } from '@customTypes/FeatureToggles';
import { GlobalMessages } from '@customTypes/Messages';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { featureToggleUtils } from '@utils/featureToggles/featureToggles';

export const callbacks = {
  async loadFeatureTogglesFromStoryblok({
    setFeatureToggles,
  }:{ setFeatureToggles: (features: FeatureToggleValues) => void }) {
    const queryParams = new URLSearchParams(window.location.search);

    const featureToggles = await featureToggleUtils.getFeatureTogglesFromStoryblok(
      queryParams.get('ccid') || localStorage.getItem(FEATURE_TOGGLES_LOCAL_STORAGE_ID) || '',
    );
    setFeatureToggles(featureToggles);
  },
};

export const hooks = {
  useFeatureTogglesStates() {
    const setFeatureToggles = messageHooks.useNext(GlobalMessages.FeatureToggles);

    return {
      setFeatureToggles,
    };
  },

  useFeatureTogglesFromStoryblok() {
    const {
      setFeatureToggles,
    } = hooks.useFeatureTogglesStates();

    const loadFeatureTogglesFromStoryblok = React.useCallback(
      () => callbacks.loadFeatureTogglesFromStoryblok({
        setFeatureToggles,
      }),
      [setFeatureToggles],
    );
    callbackHooks.useEffectCallback(loadFeatureTogglesFromStoryblok);
  },
};

export const FeatureToggleStoryblokContainer = () => {
  hooks.useFeatureTogglesFromStoryblok();

  return null;
};

FeatureToggleStoryblokContainer.displayName = 'FeatureToggleContainerStoryblok';
