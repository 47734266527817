import { ApolloClient } from '@apollo/client';

import { defaultFeatureToggles } from '@constants/featureToggle';
import { NextStaticImport } from '@containers/HeroBannerContainer/HeroBannerContainer';
import { FeatureToggleValues } from '@customTypes/FeatureToggles';
import { HeaderFooterData } from '@customTypes/apiCompound';
import { StoreDataResponse } from '@customTypes/apiTypes';
import { BaseError, BaseResponse, ErrorsV1, LanguageCodeMapped } from '@customTypes/common';
import { isResultFulfilled } from '@customTypes/typeGuards';
import { getHeaderFooterData } from '@queries/getHeaderFooterData.gql';
import { getStoreData } from '@queries/getStoreData.gql';
import { featureToggleUtils } from '@utils/featureToggles/featureToggles';

export type AppData = {
  featureToggles: FeatureToggleValues;
  storeData: BaseResponse<StoreDataResponse>;
};

const languageParamRegex =
  'en-gb|nl-nl|de-de|es-es|fr-fr|fr-be|it-it|nl-be|en-nl|en-be|en-fr|en-it|en-de|en-es';

export enum PageRootComponent {
  AccountBookingsPageContainer = 'AccountBookingsPageContainer',
  AccountGiftcardsPageContainer = 'AccountGiftcardsPageContainer',
  AccountOrdersPageContainer = 'AccountOrdersPageContainer',
  AccountOverviewPageContainer = 'AccountOverviewPageContainer',
  AccountPersonalDetailsContainer = 'AccountPersonalDetailsContainer',
  BalanceCheckerPageContainerV2 = 'BalanceCheckerPageContainerV2',
  BlogArticlePageContainer = 'BlogArticlePageContainer',
  BlogOverviewPageContainer = 'BlogOverviewPageContainer',
  BookingFailedPage = 'BookingFailedPage',
  BookingProcessingContainer = 'BookingProcessingContainer',
  BookingSuccessPage = 'BookingSuccessPage',
  BusinessPageV2Container = 'BusinessPageV2Container',
  ChangeDatesCheckoutContainer = 'ChangeDatesCheckoutContainer',
  ChangeDatesGoodToKnowPage = 'ChangeDatesGoodToKnowPage',
  ContactPage = 'ContactPage',
  CookiesAndPrivacyPage = 'CookiesAndPrivacyPage',
  GiftCardCheckoutPage = 'GiftCardCheckoutPage',
  GiftCardConfirmationPageContainerV2 = 'GiftCardConfirmationPageContainerV2',
  GiftCardPageV2Container = 'GiftCardPageV2Container',
  GiftCardProcessingContainer = 'GiftCardProcessingContainer',
  GoodToKnowPage = 'GoodToKnowPage',
  HomePageV2Container = 'HomePageV2Container',
  HotelCheckoutPageV2Container = 'HotelCheckoutPageV2Container',
  HotelDetailsPageV2Container = 'HotelDetailsPageV2Container',
  HotelListPageContainer = 'HotelListPageContainer',
  HotelSearchPageContainer = 'HotelSearchPageContainer',
  LandingPageContainer = 'LandingPageContainer',
  NewsletterSubscriptionPageContainer = 'NewsletterSubscriptionPageContainer',
  NotFoundPageContainer = 'NotFoundPageContainer',
  SomethingWentWrongPageContainer = 'SomethingWentWrongPageContainer',
  TermsAndConditionsPage = 'TermsAndConditionsPage',
}

export const isNextBuild = (): boolean => {
  return BUILD_VARIANT === 'next';
};

export const isServerSide = (): boolean => {
  return typeof window === 'undefined';
};

export const getFeatureToggles = () => featureToggleUtils.getFeatureTogglesFromStoryblok('');

export const appDataFetch = async (client: ApolloClient<unknown>): Promise<AppData> => {
  const [
    storeDataResponse, featureTogglesResponse,
  ] = await Promise.allSettled([
    client.query({ query: getStoreData }),
    getFeatureToggles(),
  ]);

  const storeData = isResultFulfilled(storeDataResponse) ? storeDataResponse.value.data : null;
  const featureToggles = isResultFulfilled(featureTogglesResponse)
    ? featureTogglesResponse.value : defaultFeatureToggles;

  return { storeData, featureToggles };
};

const mapStaticImportToSrcMap = (importedItem: string | NextStaticImport) => {
  if (!serverUtils.isNextBuild()) return importedItem as string;
  return typeof importedItem !== 'string' ? importedItem.src : importedItem;
};

export const headerFooterDataFetch = async (client: ApolloClient<unknown>):
  Promise<BaseResponse<HeaderFooterData>> => {
  const { data } = await client
    .query<BaseResponse<HeaderFooterData>>({ query: getHeaderFooterData });

  return data;
};

export const checkIfErrorPresent = (
  data: Array<{ dataPath: string[]; response: PromiseSettledResult<unknown> }>,
) => {
  return data.reduce((acc, curr) => {
    if (!isResultFulfilled(curr.response)) return true;

    const data = curr.dataPath.reduce(
      (a, c) => a?.[c], curr.response.value as Record<string, unknown>,
    ) as BaseError & ErrorsV1;

    return acc || !data || !!data.errors?.length || !!data.errors_v1?.length;
  }, false);
};

export const throwIfErrorPresent = (
  data: Record<string, { dataPath: string[]; response: PromiseSettledResult<unknown> }>,
) => {
  const hasError = serverUtils.checkIfErrorPresent(Object.values(data));

  if (!hasError) return;

  const errorObject = Object.entries(data).reduce((acc, [currKey, currValue]) => {
    acc[currKey] = isResultFulfilled(currValue.response)
      ? currValue.response.value : currValue.response.reason;

    return acc;
  }, {} as Record<string, unknown>);
  throw new Error(JSON.stringify(errorObject));
};

export const extractLanguage = (path: string | undefined) => {
  if (!path) return LanguageCodeMapped.EnGb;

  const pathWithoutLayout = path.replace(/\/desktop|\/mobile/, '');
  const pathArray = pathWithoutLayout.split('/');

  return new RegExp(languageParamRegex).test(pathArray[1])
    ? pathArray[1] as LanguageCodeMapped : LanguageCodeMapped.EnGb;
};

export const getMessages = async (
  language: LanguageCodeMapped,
  pageRootComponent: PageRootComponent,
  filteredTranslations?: boolean,
) => {
  return filteredTranslations
    ? import(`../../messages/${pageRootComponent}/${language}.json`)
      .then(data => data.default) as Promise<Record<string, string>>
    : import(`../../messages/${language}.json`)
      .then(data => data.default) as Promise<Record<string, string>>;
};

export const serverUtils = {
  isNextBuild,
  isServerSide,
  appDataFetch,
  mapStaticImportToSrcMap,
  headerFooterDataFetch,
  checkIfErrorPresent,
  throwIfErrorPresent,
  getFeatureToggles,
  extractLanguage,
  getMessages,
};
