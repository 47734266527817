import { Locale } from 'date-fns';
import { de, enGB, es, fr, it, nl, nlBE } from 'date-fns/locale';

import { HotelPriceBreakdownItem } from '@components/HotelPriceBreakdown/HotelPriceBreakdown';

import {
  Currency,
  LangCode,
  UrlType,
  GiftCardType,
  CountryCode,
  CustomerType,
  GiftCardPaymentPartialPurchaseReason,
} from './apiEnums';

export enum LanguageCodeMapped {
  DeDe = 'de-de',
  EnBe = 'en-be',
  EnDe = 'en-de',
  EnEs = 'en-es',
  EnFr = 'en-fr',
  EnGb = 'en-gb',
  EnIt = 'en-it',
  EnNl = 'en-nl',
  EsEs = 'es-es',
  FrBe = 'fr-be',
  FrFr = 'fr-fr',
  ItIt = 'it-it',
  NlBe = 'nl-be',
  NlNl = 'nl-nl',
}

export const newDatePickerFormat = 'EE d MMM';

export const deliveryDateFormat = 'EEEE d/MM/yyyy';

export const langToDateFnsLocale: Record<LanguageCodeMapped, Locale> = {
  [LanguageCodeMapped.NlNl]: nl,
  [LanguageCodeMapped.NlBe]: nlBE,
  [LanguageCodeMapped.EnNl]: enGB,
  [LanguageCodeMapped.EnGb]: enGB,
  [LanguageCodeMapped.DeDe]: de,
  [LanguageCodeMapped.EnDe]: enGB,
  [LanguageCodeMapped.FrFr]: fr,
  [LanguageCodeMapped.EnFr]: enGB,
  [LanguageCodeMapped.ItIt]: it,
  [LanguageCodeMapped.EnIt]: enGB,
  [LanguageCodeMapped.FrBe]: fr,
  [LanguageCodeMapped.EnBe]: enGB,
  [LanguageCodeMapped.EsEs]: es,
  [LanguageCodeMapped.EnEs]: enGB,
};

export enum ComponentIds {
  FooterV3 = 'footer-v3',
  Map = 'anchor-map',
  Reviews = 'anchor-reviews',
  RoomList = 'anchor-roomlist',
}

export type OccupancyPresentational = {
  adults: number;
  children: number[];
};

export type Error = { code?: number; message: string };

export type BaseError = { errors?: Error[] };

export type ErrorV1 = {
  code: number;
  referencedRequestParameter?: string;
};

export type ErrorsV1 = { errors_v1: ErrorV1[] };

export type BaseResponse<T,> = {
  data: T & BaseError;
};

export type BaseResponseV2<T,> = {
  data: T & ErrorsV1;
};

export type DateRangeType = {
  from: string;
  to: string;
};

export type Amenities = {
  id: string;
  name: string;
};

export type Bulletpoint = {
  id: string;
  name: string;
};

export type RangeSelectedEvent = {
  endDate: Date;
  startDate: Date;
};

export type Size = {
  height: number;
  width: number;
};

export type SelectItem = {
  icon: JSX.Element;
  id: string;
  label: string;
  radioButton?: JSX.Element;
};

export type BankItem = {
  id: string;
  logo: string;
  name: string;
};

export enum AdjustmentType {
  Promotion = 'promotion',
  Shipping = 'shipping',
}

export enum DeliveryOptions {
  DeliverToMe = 'deliverToMe',
  DeliverToOther = 'deliverToOther',
}

export type PricesBreakdown = {
  adjustments: PricePresentational;
  affiliateServiceCharge: PricePresentational | null;
  averagePerNight: PricePresentational | null;
  couponDiscount: PricePresentational | null;
  grandTotal: PricePresentational | null;
  mandatoryFees: PricePresentational | null;
  mandatoryFeesBillable: PricePresentational | null;
  mandatoryTaxes: PricePresentational | null;
  mandatoryTaxesBillable: PricePresentational | null;
  otherFees: PricePresentational | null;
  propertyFeeDueNow: PricePresentational | null;
  quantityNights?: number;
  resortFees: PricePresentational | null;
  resortFeesBillable: PricePresentational | null;
  salesTaxes: PricePresentational | null;
  subtotalPrice: PricePresentational | null;
  taxesAndFees: PricePresentational | null;
  totalDueNow: PricePresentational | null;
  totalPaid: PricePresentational | null;
  totalPayLater: PricePresentational | null;
};

export type HotelPoint = {
  coordinates: CoordinatesPresentational;
  id: string;
  title: string;
};

export type PricePresentational = {
  currency: Currency;
  value: number;
};

export type PriceRangePresentational = {
  currency: Currency;
  maxPrice: number;
  minPrice: number;
};

export type StarsRatingFilterInputPresentational = {
  Five: boolean | null;
  Four: boolean | null;
  One: boolean | null;
  Three: boolean | null;
  Two: boolean | null;
};

export type ImageHrefPresentational = {
  href: string;
  size: string;
};

export type ImagePresentational = {
  alt: string | null;
  isHero: boolean | null;
  url: ImageHrefPresentational[];
};

export type LinkPresentational = {
  title: string;
  url: string;
};

export type MetaDataPresentational = {
  canonical: string | null;
  description: string | null;
  hreflangTags: HreflangTagPresentational[] | null;
  keywords: string[] | null;
  nofollow: boolean | null;
  noindex: boolean | null;
  og: OpenGraphMetaPresentational | null;
  title: string;
  twitter: TwitterMetaPresentational | null;
};

export type HreflangTagPresentational = {
  langCode: LangCode;
  path: UrlObjectPresentational;
};

export type UrlObjectPresentational = {
  href: string;
  type: UrlType;
};

export type OpenGraphMetaPresentational = {
  articlePublisher: string | null;
  articleSection: string | null;
  contentType: string | null;
  description: string;
  image: string;
  imageSecure: string | null;
  locale: string | null;
  siteName: string | null;
  title: string;
  url: string;
};

export type TwitterMetaPresentational = {
  card: string;
  creator: string | null;
  description: string | null;
  image: string | null;
  site: string | null;
  title: string;
};

export type ColorPresentational = {
  color: string;
  opacity: number;
};

export type WrapperItem = {
  backgroundColor: ColorPresentational | null;
  id: string;
  image: { alt: string; url: string };
  price: string | JSX.Element;
  title: string;
};

export type CoordinatesPresentational = {
  lat: number;
  lon: number;
};

export type GiftcardsShipmentItem = {
  quantity: number;
  title: string | JSX.Element;
};

export type PackagesDataType = {
  [GiftCardType.DIGITAL]: GiftcardsShipmentItem[];
  [GiftCardType.PHYSICAL]: GiftcardsShipmentItem[];
};

export type ShipmentsData = {
  deliveryDate: string;
  email: string;
  packages: PackagesDataType;
};

export type CancelPenaltyPresentational = {
  amount: number | null;
  currency: Currency;
  endTime: number | string | null;
  nights: number | null;
  percent: string | null;
  startTime: number | string | null;
};

export type AmenityPresentational = {
  icon: ImagePresentational | null;
  id: string;
  name: string;
};

export type BedGroupInfo = {
  description: string;
  id: string;
  priceCheckToken?: string | null;
};

export type BlockTitleAndBodyPresentational = {
  body: string;
  title: string;
};

export type HotelSummary = {
  address: AddressPresentational | null;
  brandLogo: ImagePresentational | null;
  bulletpoints: BulletpointPresentational[] | null;
  gallery: ImagePresentational[] | null;
  id: string;
  location: CoordinatesPresentational | null;
  minimalTotalPrice: PricePresentational | null;
  rating: number | null;
  title: string;
  urls: LangDependentUrlPresentational[] | null;
};

export type AddressPresentational = {
  addressLine1: string | null;
  addressLine2: string | null;
  city: string;
  countryCode: CountryCode;
  countryName: string;
  houseNumber: string | null;
  line_1: string | null;
  locality: string | null;
  postalCode: string;
  referenceNumber: string | null;
  street: string | null;
  suffix: string | null;
  taxNumber: string | null;
};

export type BulletpointPresentational = {
  id: string;
  name: string;
};

export type LangDependentUrlPresentational = {
  langCode: LangCode;
  url: string;
};

export type CoordinateRectanglePresentational = {
  latmax: number;
  latmin: number;
  lonmax: number;
  lonmin: number;
};

export type RoomOccupancyPresentational = {
  adults: number;
  children: number[] | null;
};

export type BedGroupConfigurationPresentational = {
  bedType: string;
  quantity: number;
  size: string;
};

export type BedGroupPresentational = {
  bedGroupConfigurations: BedGroupConfigurationPresentational[] | null;
  description: string;
  id: string;
  priceCheckToken: null | string;
};

export type OccupancyPricingPresentational = {
  affiliateServiceCharge: PricePresentational | null;
  averagePerNight: PricePresentational;
  grandTotal: PricePresentational;
  mandatoryFees: PricePresentational | null;
  mandatoryFeesBillable: PricePresentational | null;
  mandatoryTaxes: PricePresentational | null;
  mandatoryTaxesBillable: PricePresentational | null;
  occupancy: RoomOccupancyPresentational;
  otherFees: PricePresentational | null;
  propertyFeeDueNow: PricePresentational | null;
  resortFees: PricePresentational | null;
  resortFeesBillable: PricePresentational | null;
  salesTaxes: PricePresentational | null;
  strikethrough: PricePresentational | null;
  subTotal: PricePresentational;
  taxesAndFees: PricePresentational | null;
  totalPayLater: PricePresentational | null;
  totalPayLaterBillable: PricePresentational | null;
  totalPayNow: PricePresentational;
};

export type RoomRatePresentational = {
  amenities: AmenityPresentational[] | null;
  bedGroups: BedGroupPresentational[] | null;
  cancelPenalty: CancelPenaltyPresentational[] | null;
  id: string;
  nonRefundableDatesRanges: DatesRangePresentational[];
  pricing: OccupancyPricingPresentational[] | null;
  refundable: boolean;
};

export type RoomOccupancyRequirementsPresentational = {
  adultMinAge: number;
  maxAdults: number;
  maxChildren: number;
  maxTotalPersons: number;
};

export type RoomPresentational = {
  amenities: AmenityPresentational[] | null;
  description: string | null;
  entityBundle: string | null;
  entityType: string | null;
  gallery: ImagePresentational[] | null;
  id: string;
  isAvailable: boolean | null;
  maxOccupancy: RoomOccupancyRequirementsPresentational | null;
  name: string;
  roomBedGroups: BedGroupPresentational[] | null;
  roomRates: RoomRatePresentational[] | null;
  squareFeets: number | null;
  squareMeters: number | null;
};

export type DatesRangePresentational = {
  end: string;
  start: string;
};

export type IpLocation = {
  country_code: CountryCode;
  region_code: string;
};

export type CustomerPresentatinal = {
  companyName: string | null;
  customerType: CustomerType | null;
  email: string | null;
  firstName: string | null;
  infix: string | null;
  lastName: string | null;
  phoneNumber: string | null;
};

export type PersonalInfoPresentational = {
  address: AddressPresentational;
  customer: CustomerPresentatinal;
};

export type OrderCouponPresentational = {
  code: string;
  id: string;
  value: PricePresentational | null;
};

export type GiftCardPaymentPresentational = {
  amount: PricePresentational;
  balance: PricePresentational;
  giftCard: {
    giftCardCode: string;
  };
  id: string;
  partialPurchaseReason?: GiftCardPaymentPartialPurchaseReason | null;
};

export type BuckarooBankPresentational = {
  id: string;
  logo: ImagePresentational | null;
  name: string | null;
};

export type PaymentMethodPresentational = {
  banks?: BuckarooBankPresentational[] | null;
  id: string;
  logo: ImagePresentational | null;
  name: string;
};

export type PaymentGatewayPresentational = {
  banks?: BuckarooBankPresentational[] | null;
  id: string;
  logo: ImagePresentational | null;
  name: string;
  typeId: string;
};

export type RoutingParams = {
  language?: LanguageCodeMapped;
};

export enum PaymentGatewayTypeId {
  adyen = 'adyen_credit_card',
  adyen_apple_pay = 'adyen_apple_pay',
  adyen_paypal = 'adyen_paypal',
  apple_pay = 'buckaroo_json_apple_pay',
  bancontact = 'buckaroo_json_bancontact',
  belfius = 'buckaroo_json_belfius',
  credit_card = 'buckaroo_json_credit_card',
  giropay = 'buckaroo_json_giropay',
  googlepay = 'adyen_google_pay',
  ideal = 'buckaroo_json_ideal',
  kbc = 'buckaroo_json_kbc',
  klarna = 'adyen_klarna',
  przelewy24 = 'buckaroo_json_przelewy24',
  sofort = 'buckaroo_json_sofort',
  trustly = 'buckaroo_json_trustly',
}

export type TitleImageBlockPresentational = {
  image: ImagePresentational;
  title: string;
};

export type TitleContactIconDescriptionBlockPresentational = {
  contact: string;
  description: string;
  image: ImagePresentational;
  title: string;
};

export type TitleBodyImageBlockPresentational = {
  body: string;
  image: ImagePresentational;
  title: string;
};

export type IconNameLinkBlockPresentational = {
  iconName: string;
  link: LinkPresentational;
};

export type TitleLinksBlockPresentational = {
  links: LinkPresentational[];
  title: string;
};

export type TitleBodyImageLinkBlock = {
  body: string;
  image: ImagePresentational;
  link: LinkPresentational;
  title: string;
};

export type FieldWeightsPresentational = {
  value: string;
  weight: number;
};

export type OrderResponsePresentational = {
  errors_v1?: ErrorV1[];
};

export enum AccountPages {
  Bookings = 'bookings',
  Giftcards = 'giftcards',
  Logout = 'logout',
  Orders = 'orders',
  Overview = 'overview',
  PersonalDetails = 'personalDetails',
}

export type AddressProfilePresentational = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city: string | null;
  companyName: string | null;
  countryCode: CountryCode;
  houseNumber: string | null;
  postalCode: string | null;
  referenceNumber: string | null;
  street: string | null;
  suffix: string | null;
};

export type StatusResponsePresentational = {
  errors: Error[] | null;
  errors_v1: Array<{
    code: number;
    message: string;
    referencedRequestParameter: string | null;
  }> | null;
  success: boolean;
};

export type PagingPresentational = {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
};

export type BuckarooJsonCreditCardPresentational = {
  id: string;
  logo: ImagePresentational | null;
  name: string;
  typeId: string;
};

export enum CreditCardType {
  AMERICAN_EXPRESS = 'american express',
  AMEX = 'amex',
  ARGENCARD = 'argencard',
  BANCONTACT = 'bcmc',
  BIJ = 'bijcard',
  CABAL = 'cabal',
  CB = 'cartebancaire',
  CODENSA = 'codensa',
  CUP = 'cup',
  DANKORT = 'dankort',
  DINERS = 'diners',
  DISCOVER = 'discover',
  ELECTRON = 'electron',
  ELO = 'elo',
  FBF = 'forbrugsforeningen',
  HIPER = 'hiper',
  HIPERC = 'hipercard',
  INTERPAYMENT = 'interpayment',
  JCB = 'jcb',
  KM = 'karenmillen',
  LASER = 'laser',
  MAESTRO = 'maestro',
  MAESTROUK = 'maestrouk',
  MASTERCARD = 'mastercard',
  MC = 'mc',
  MCAB = 'mcalphabankbonus',
  MIR = 'mir',
  NARANJA = 'naranja',
  OASIS = 'oasis',
  RUPAY = 'rupay',
  SHOPPING = 'shopping',
  SOLO = 'solo',
  TROY = 'troy',
  UATP = 'uatp',
  UNIONPAY = 'unionpay',
  VAB = 'visaalphabankbonus',
  VD = 'visadankort',
  VISA = 'visa',
  VPAY = 'vpay',
  WAREHOUSE = 'warehouse',
}

export const creditCardNames: Record<string, string> = {
  [CreditCardType.ELECTRON]: 'Electron',
  [CreditCardType.MAESTRO]: 'Maestro',
  [CreditCardType.DANKORT]: 'Dankort',
  [CreditCardType.INTERPAYMENT]: 'InterPayments',
  [CreditCardType.UNIONPAY]: 'UnionPay',
  [CreditCardType.VISA]: 'Visa',
  [CreditCardType.MASTERCARD]: 'Mastercard',
  [CreditCardType.AMERICAN_EXPRESS]: 'Amex',
  [CreditCardType.DINERS]: 'Diners Club',
  [CreditCardType.DISCOVER]: 'Discover',
  [CreditCardType.JCB]: 'JCB',
};

export const creditCardNamesMapperToTypes: Record<string, CreditCardType> = {
  'American express': CreditCardType.AMERICAN_EXPRESS,
  'Mastercard': CreditCardType.MASTERCARD,
  'Visa': CreditCardType.VISA,
  'Electron': CreditCardType.ELECTRON,
  'Maestro': CreditCardType.MAESTRO,
  'Vpay': CreditCardType.VPAY,
};

export type PaymentMethod = {
  banks?: BuckarooBankPresentational[] | null;
  id: string;
  logo: ImagePresentational | null;
  name: string;
  typeId: string;
};

export type PaymentMethodItem = PaymentMethod & {
  checked: boolean;
  key: string;
};

export type PaymentSystem = PaymentGatewayTypeId.credit_card | PaymentGatewayTypeId.adyen;

export enum OrderState {
  canceled = 'canceled',
  completed = 'completed',
  draft = 'draft',
  failed = 'failed',
  payments_received = 'payments_received',
  waiting_for_booking = 'waiting_for_booking',
  waiting_for_external_payment = 'waiting_for_external_payment',
  waiting_for_internal_payment = 'waiting_for_internal_payment',
}

export type Pricing = {
  alreadyPaid: HotelPriceBreakdownItem | null;
  alreadyPaidAndDiscount: HotelPriceBreakdownItem | null;
  alreadyPaidBold: HotelPriceBreakdownItem | null;
  coupons: HotelPriceBreakdownItem[];
  giftcards: HotelPriceBreakdownItem[];
  grandTotal: HotelPriceBreakdownItem;
  hotelCharges: HotelPriceBreakdownItem[];
  orderCharges: HotelPriceBreakdownItem[];
  totalPayLater: HotelPriceBreakdownItem | null;
  totalPayLaterBillable: HotelPriceBreakdownItem | null;
};

export type CustomerProfilePresentational = {
  address: AddressProfilePresentational;
  customerType: CustomerType;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
};

export type UserGiftCardPresentational = {
  activatedDate: string;
  balance: PricePresentational | null;
  brandName: string | null;
  deleted: boolean | null;
  expired: boolean | null;
  expiryDate: string;
  giftCardCode: string;
  giftCardName: string;
  id: string;
  uuid: string;
};

export type CreditCardPaymentPresentational = {
  __typename?: 'CreditCardPayment';
  amount: PricePresentational;
  id: string;
  name: string;
};

export type SingleImageBlockPresentational = {
  image: ImagePresentational;
  mobileImage: ImagePresentational;
};

export type MediaBlockPresentational = {
  singleImage: SingleImageBlockPresentational | null;
  sliderImages: SingleImageBlockPresentational[] | null;
  twoImages: SingleImageBlockPresentational[] | null;
  video: LinkPresentational | null;
};

export type ContentBlocksType = {
  formattedText: string | null;
  mediaBlock: MediaBlockPresentational | null;
  title: string | null;
};

export type FeaturedArticleType = {
  link: string;
  publishedAt: string;
  teaserImage: SingleImageBlockPresentational | null;
  title: string;
};
