import { FeatureToggle, FeatureToggleValues } from '@customTypes/FeatureToggles';

export const FEATURE_TOGGLES_LOCAL_STORAGE_ID = 'featureTogglesId';

export const FEATURE_TOGGLES_ENVIRONMENT_FOLDER = `hotel-gift-card/${
  typeof CONFIG !== 'undefined' ? CONFIG.storyblokEnvironmentFolder : 'staging'
}`;
export const FEATURE_TOGGLES_PACKAGE_FOLDER = 'feature-toggles';
export const FEATURE_TOGGLES_TOKEN = 'bbr6S8iPmBmrHADh2ySbEQtt';

export const defaultFeatureToggles: FeatureToggleValues = {
  [FeatureToggle.adyenApplePay]: false,
  [FeatureToggle.maintenanceMode]: false,
  [FeatureToggle.bookingManitenanceMode]: false,
  [FeatureToggle.adyenPayments]: false,
  [FeatureToggle.creditCardMerge]: false,
  [FeatureToggle.promoPopup]: false,
  [FeatureToggle.accountRelease1]: false,
  [FeatureToggle.accountRelease2]: false,
  [FeatureToggle.accountRelease3]: false,
  [FeatureToggle.accountRelease4]: false,
  [FeatureToggle.accountCheckout]: false,
  [FeatureToggle.buckarooApplePay]: false,
  [FeatureToggle.accountInBuyFlow]: false,
  [FeatureToggle.accountPrefill]: false,
  [FeatureToggle.accountCancelBooking]: false,
  [FeatureToggle.thankYouPageRegistration]: false,
  [FeatureToggle.trustBadge]: false,
  [FeatureToggle.blogPage]: false,
  [FeatureToggle.newExtrasModalFF]: false,
  [FeatureToggle.adyenGooglePay]: false,
  [FeatureToggle.digitalPersonalMessage]: false,
  [FeatureToggle.physicalDeliveryFr]: false,
  [FeatureToggle.filteredTranslations]: false,
};

export const defaultFeatureTogglesMock = {
  featureToggle1: false,
  featureToggle2: false,
  featureToggle3: false,
} as unknown as FeatureToggleValues ;
