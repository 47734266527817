export enum FeatureToggle {
  accountCancelBooking = 'accountCancelBooking',
  accountCheckout = 'accountCheckout',
  accountInBuyFlow = 'accountInBuyFlow',
  accountPrefill = 'accountPrefill',
  accountRelease1 = 'accountRelease1',
  accountRelease2 = 'accountRelease2',
  accountRelease3 = 'accountRelease3',
  accountRelease4 = 'accountRelease4',
  adyenApplePay = 'adyenApplePay',
  adyenGooglePay = 'adyenGooglePay',
  adyenPayments = 'adyenPayments',
  blogPage = 'blogPage',
  bookingManitenanceMode = 'bookingManitenanceMode',
  buckarooApplePay = 'buckarooApplePay',
  creditCardMerge = 'creditCardMerge',
  digitalPersonalMessage = 'digitalPersonalMessage',
  filteredTranslations = 'filteredTranslations',
  maintenanceMode = 'maintenanceMode',
  newExtrasModalFF = 'newExtrasModalFF',
  physicalDeliveryFr = 'physicalDeliveryFr',
  promoPopup = 'promoPopup',
  thankYouPageRegistration = 'thankYouPageRegistration',
  trustBadge = 'trustBadge',
}

export type FeatureToggleValues = {
  [FeatureToggle.adyenApplePay]: boolean;
  [FeatureToggle.maintenanceMode]: boolean;
  [FeatureToggle.bookingManitenanceMode]: boolean;
  [FeatureToggle.adyenPayments]: boolean;
  [FeatureToggle.creditCardMerge]: boolean;
  [FeatureToggle.promoPopup]: boolean;
  [FeatureToggle.accountRelease1]: boolean;
  [FeatureToggle.accountRelease2]: boolean;
  [FeatureToggle.accountRelease3]: boolean;
  [FeatureToggle.accountRelease4]: boolean;
  [FeatureToggle.accountCheckout]: boolean;
  [FeatureToggle.buckarooApplePay]: boolean;
  [FeatureToggle.accountInBuyFlow]: boolean;
  [FeatureToggle.accountPrefill]: boolean;
  [FeatureToggle.accountCancelBooking]: boolean;
  [FeatureToggle.trustBadge]: boolean;
  [FeatureToggle.thankYouPageRegistration]: boolean;
  [FeatureToggle.blogPage]: boolean;
  [FeatureToggle.newExtrasModalFF]: boolean;
  [FeatureToggle.adyenGooglePay]: boolean;
  [FeatureToggle.digitalPersonalMessage]: boolean;
  [FeatureToggle.physicalDeliveryFr]: boolean;
  [FeatureToggle.filteredTranslations]: boolean;
};
