import React from 'react';
import { ToastContainer } from 'react-toastify';

import { AppProps } from 'next/app';
import Script from 'next/script';

import '../styles/nextStyles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import '@adyen/adyen-web/dist/adyen.css';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import 'react-datepicker/dist/react-datepicker.css';

import { seonHooks } from '@nxte-nl/seon-hooks';

import { FeatureToggleStoryblokContainer }
  from '@containers/FeatureToggleStoryblokContainer/FeatureToggleStoryblokContainer';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { hydrationHooks } from '@hooks/hydrationHooks/hydrationHooks';
import { serverUtils } from '@utils/serverUtils/serverUtils';

config.autoAddCss = false;

export default function App({ Component, pageProps }: AppProps) {
  seonHooks.useSetConfig();
  const isServerSide = serverUtils.isServerSide();
  const { isClient } = hydrationHooks.useIsClient();

  return (
    <>
      {/* Google Tag Manager */}
      {CONFIG.isLive ? (
        <>
          <Script
            id='gtm-hgc'
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
              __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WC62JLM');
          `,
            }}
          />
        </>
      ) : null}
      {/* End Google Tag Manager */}
      {/* Google Tag Manager (noscript) */}
      {CONFIG.isLive ? (
        <>
          <noscript><iframe src='https://www.googletagmanager.com/ns.html?id=GTM-WC62JLM'
            height='0' width='0'
            style={{ display: 'none', visibility: 'hidden' }}></iframe></noscript>
        </>
      ) : null}
      {/* End Google Tag Manager (noscript) */}
      <Component {...pageProps} />
      {!isServerSide && <FeatureToggleStoryblokContainer />}
      {isClient && <ToastContainer />}
    </>
  );
}
