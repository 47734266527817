import React from 'react';

import { BehaviorSubject, Subject } from 'rxjs';

const messageBusGlobal: Record<string, Subject<unknown>> = {};

export type MessagebusRecord = Record<string | number | symbol, unknown>;

export type Messagebus<
  TStateMessages extends MessagebusRecord,
  TSignalMessages extends MessagebusRecord,
  TStateKey extends keyof TStateMessages = keyof TStateMessages,
  TSignalKey extends keyof TSignalMessages = keyof TSignalMessages,
  > = {
  [key in keyof (TStateMessages | TSignalMessages)]?:
  Subject<TStateMessages[TStateKey] | TSignalMessages[TSignalKey] | null> | null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyImplementation = () => {};

export const callbacks = {
  getBehaviorSubject<T extends MessagebusRecord, U extends keyof T = keyof T,>(
    key: U,
    defaults: Partial<T>,
  ) {
    const messagebus = messageBusGlobal as Messagebus<T, T>;
    return messagebus[key] = messagebus[key] as BehaviorSubject<T[U] | null>
      || new BehaviorSubject<Partial<T>[U] | null>(defaults[key] ?? null);
  },
  getSubject<T extends MessagebusRecord, U extends keyof T = keyof T,>(
    key: U,
  ) {
    const messagebus = messageBusGlobal as Messagebus<T, T>;
    return messagebus[key] = messagebus[key] as Subject<T[U]> || new Subject<T[U]>();
  },
};

export type MessageHooks<T extends MessagebusRecord, K extends MessagebusRecord,> = {
  useNext<U extends keyof T = keyof T,>(key: U): (value: T[U]) => void;
  useNextSignal<U extends keyof K = keyof K,>(key: U): (value: K[U]) => void;
  useNextSignalWithValue<U extends keyof K = keyof K,>(
    key: U,
    value: K[U],
  ): () => void;
  useNextWithValue<U extends keyof T = keyof T,>(key: U, value: T[U]): () => void;
  useSubjectState<U extends keyof T = keyof T,>(key: U): T[U];
  useSubscribe<U extends keyof T = keyof T,>(key: U, callback: (value: T[U]) => void): void;
  useSubscribeSignal<U extends keyof K = keyof K,>(key: U, callback: (value: K[U]) => void): void;
};

export const messageHooksFactory = <
  TStateMessages extends MessagebusRecord,
  TSignalMessages extends MessagebusRecord,
  >(defaults: Partial<TStateMessages> = {}) => {
  const hooks: MessageHooks<TStateMessages, TSignalMessages> = {
    useNext<U extends keyof TStateMessages = keyof TStateMessages,>(key: U) {
      const subject = callbacks.getBehaviorSubject<TStateMessages, U>(key, defaults);
      return React.useCallback((value: TStateMessages[U]) => {
        subject.next(value);
      }, [subject]);
    },
    useSubjectState<U extends keyof TStateMessages = keyof TStateMessages,>(
      key: U,
    ) {
      return callbacks
        .getBehaviorSubject<TStateMessages, U>(key, defaults).getValue() as TStateMessages[U];
    },
    useSubscribe<U extends keyof TStateMessages = keyof TStateMessages,>(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      key: U, callback: (value: TStateMessages[U]) => void,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    ) {},
    useNextWithValue<U extends keyof TStateMessages = keyof TStateMessages,>(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      key: U, value: TStateMessages[U],
    ) {
      return emptyImplementation;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    useNextSignal<U extends keyof TSignalMessages = keyof TSignalMessages,>(key: U) {
      return emptyImplementation;
    },
    useNextSignalWithValue<U extends keyof TSignalMessages = keyof TSignalMessages,>(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      key: U, value: TSignalMessages[U],
    ) {
      return emptyImplementation;
    },
    useSubscribeSignal<U extends keyof TSignalMessages = keyof TSignalMessages,>(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      key: U, callback: (value: TSignalMessages[U]) => void,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    ) {},
  };

  return hooks;
};

