import { clientKit, FeatureFlags } from '@nxte-nl/feature-flags-client-kit';

import {
  defaultFeatureToggles,
  FEATURE_TOGGLES_ENVIRONMENT_FOLDER,
  FEATURE_TOGGLES_PACKAGE_FOLDER,
  FEATURE_TOGGLES_TOKEN } from '@constants/featureToggle';
import { FeatureToggleValues } from '@customTypes/FeatureToggles';

export const featureToggleUtils = {
  async getFeatureTogglesFromStoryblok(ccid: string): Promise<FeatureToggleValues> {
    const {
      getValues,
      mergeValues,
    } = clientKit.createClient<FeatureFlags>({
      environmentFolder: FEATURE_TOGGLES_ENVIRONMENT_FOLDER,
      packageFolder: FEATURE_TOGGLES_PACKAGE_FOLDER,
      token: FEATURE_TOGGLES_TOKEN,
    });

    const values = await getValues();

    const featureFlagsRecord = mergeValues({
      data: values || null,
      userIdentifier: ccid,
    });

    return { ...defaultFeatureToggles, ...featureFlagsRecord };
  },
};
