var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"hsiEu37qhwlh45tGqbT4b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

if (CONFIG.isLive) {
  Sentry.init({
    dsn: 'https://031964b9e50947aeb05eaf350f121d1a@o1354268.ingest.sentry.io/6691408',
    integrations: [
      new Sentry.BrowserTracing(),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.002,
    tracesSampleRate: 0.1,
    environment: CONFIG.env,
  });
}
