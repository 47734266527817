import { SuggestedItem } from '@hooks/autocompleteHooks/autocompleteHooks';

import {
  Entity,
} from './Entities';
import { FeatureToggleValues } from './FeatureToggles';
import { FormPickerAlignValues } from './FormPickerAlignValues';
import { Modal } from './Modal';
import { LayoutExtension } from './ResponsiveState';
import {
  ApiGiftCardOrderResponse,
  ApiHotelListPricesResponse,
  ApiHotelBookingOrderResponse,
  HeaderFooterData,
  UserInfoData,
} from './apiCompound';
import { LanguageSpecificUrls } from './apiCompoundLanguageSpecificUrl';
import {
  HGC_StatementPage,
  HGC_TermsConditionsPage,
  Region,
  Hotel,
  HotelConfigurableFilterGroupResponse,
  HotelConnection,
  UserLocationResponse,
  HGC_GiftCardProductPage,
  StoreDataResponse,
  DeliveryDatesResponse,
  HGC_BusinessRequestPage,
  HGC_HomePage_v2,
  HGC_BusinessRequestPage_v2,
  HGC_LandingPage,
  HGC_CardBalanceCheckPage,
  HGC_BlogOverviewPage, HGC_BlogArticle,
} from './apiTypes';
import { BaseResponse, IpLocation, LanguageCodeMapped } from './common';

export type PageEntity =
  | Entity<HGC_HomePage_v2>
  | Entity<Region>
  | Entity<Hotel>
  | Entity<HGC_StatementPage>
  | Entity<HGC_TermsConditionsPage>
  | Entity<HGC_GiftCardProductPage>
  | Entity<HGC_BusinessRequestPage>
  | Entity<HGC_BusinessRequestPage_v2>
  | Entity<HGC_LandingPage>
  | Entity<HGC_CardBalanceCheckPage>
  | Entity<HGC_BlogOverviewPage>
  | Entity<HGC_BlogArticle>;

export enum GlobalMessages {
  CountrySelectPanelShown = 'countrySelectPanelShown',
  Entity = 'entity',
  EntityLoading = 'entityLoading',
  FeatureToggles = 'featureToggles',
  FormPickerAlign = 'formPickerAlign',
  HeaderFooterData = 'headerFooterData',
  IpLocation = 'ipLocation',
  IsMobileDateOccupancyOpen = 'IsMobileDateOccupancyOpen',
  Language = 'language',
  LayoutExtension = 'layoutExtension',
  ModalOpen = 'modalOpen',
  StoreData = 'storeData',
  Urls = 'Urls',
  UserLocation = 'userLocation',
}

export enum FilterMessages {
  ConfigurableFilters = 'configurableFilters',
  LocationData = 'locationData',
  ModalFiltersSelected = 'modalFiltersSelected',
}

export enum HotelListMessages {
  HotelList = 'hotelList',
  ListLoading = 'listLoading',
}

export enum MobileFilterMessages {
  FilterPrices = 'filterPrices',
}

export enum GiftCardCheckoutMessages {
  GiftcardBasket = 'giftcardBasket',
  GiftcardBasketLoading = 'GiftcardBasketLoading',
  GiftcardBasketModalDisabled = 'GiftcardBasketModalDisabled',
}

export enum HotelCheckoutMessages {
  ChangeId = 'changeId',
  HotelBasket = 'hotelBasket',
  OpenCalendar = 'openCalendar',
  ScrollToRooms = 'scrollToRooms',
  Uuid = 'uuid',
}

export enum DeliveryDatesMessages {
  DeliveryDates = 'deliveryDates',
}

export enum AccountMessages {
  UserInfo = 'userInfo',
  UserInfoLoading = 'userInfoLoading',
}

export type Messages = {
  [GlobalMessages.Language]: LanguageCodeMapped | null;
  [GlobalMessages.LayoutExtension]: LayoutExtension | null;
  [GlobalMessages.Entity]: BaseResponse<PageEntity> | null;
  [GlobalMessages.EntityLoading]: boolean | null;
  [GlobalMessages.FeatureToggles]: FeatureToggleValues;
  [GlobalMessages.ModalOpen]: Modal | null;
  [GlobalMessages.Urls]: LanguageSpecificUrls | null;
  [GlobalMessages.FormPickerAlign]: FormPickerAlignValues | null;
  [GlobalMessages.UserLocation]: UserLocationResponse | null;
  [GlobalMessages.CountrySelectPanelShown]: boolean;
  [GlobalMessages.StoreData]: BaseResponse<StoreDataResponse> | null;
  [GlobalMessages.IpLocation]: IpLocation | null;
  [GlobalMessages.HeaderFooterData]: BaseResponse<HeaderFooterData> | null;
  [GlobalMessages.IsMobileDateOccupancyOpen]: boolean;
  [FilterMessages.ModalFiltersSelected]: boolean;
  [FilterMessages.LocationData]: SuggestedItem | null;
  [FilterMessages.ConfigurableFilters]: BaseResponse<HotelConfigurableFilterGroupResponse> | null;
  [HotelListMessages.ListLoading]: boolean | null;
  [MobileFilterMessages.FilterPrices]: ApiHotelListPricesResponse | null;
  [HotelListMessages.HotelList]: BaseResponse<HotelConnection> | null;
  [GiftCardCheckoutMessages.GiftcardBasket]: ApiGiftCardOrderResponse | null;
  [GiftCardCheckoutMessages.GiftcardBasketLoading]: boolean;
  [GiftCardCheckoutMessages.GiftcardBasketModalDisabled]: boolean;
  [DeliveryDatesMessages.DeliveryDates]: BaseResponse<DeliveryDatesResponse> | null;
  [HotelCheckoutMessages.HotelBasket]: ApiHotelBookingOrderResponse | null;
  [HotelCheckoutMessages.ScrollToRooms]: boolean | null;
  [HotelCheckoutMessages.OpenCalendar]: boolean | null;
  [HotelCheckoutMessages.Uuid]: string | null;
  [HotelCheckoutMessages.ChangeId]: string | null;
  [AccountMessages.UserInfo]: UserInfoData | null;
  [AccountMessages.UserInfoLoading]: boolean;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type Signals = {};
