import React from 'react';

export const hydrationHooks = {
  useIsClient() {
    // react hydration issue
    // https://nextjs.org/docs/messages/react-hydration-error

    const [isClient, setIsClient] = React.useState(false);

    React.useEffect(() => {
      setIsClient(true);
    }, []);

    return { isClient };
  },
};
